/** @format */

import React, { useState } from "react";
import { Form } from "react-bootstrap";

import "../css/style.css";
import "../css/requestForm.css";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { addToken, onASNameChange } from "../actions";
import { GLOBAL_DOMAIN } from "../actions/types";
import axios from "axios";
import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { Link } from "react-router-dom";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState("");

  const { token } = useSelector((state) => state.auth);
  const attemptLogin = async () => {
    if (email === "" || password === "") {
      setLoginError("Please enter a valid email and/or password.");
      setLoading(false);
      setTimeout(() => {
        setLoginError("");
      }, 3000);
      return;
    }
    let response = { access_token: token };
    if (!token) {
      try {
        response = await getSalesforceToken();
        // 1a) Token success? If not, post alert. If yes, add to reducer
        if (!response.success) {
          setLoginError("Error Verifying Account");
          setLoading(false);
          return;
        } else {
          props.addToken(response.access_token);
          localStorage.setItem("token", response.access_token);
        }
      } catch (err) {
        setLoginError("Error Logging In");
        setLoading(false);
        // props.onLIError("Error Logging In");
        return;
      }
    }
    try {
      let auth = getAuth();
      let userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // .then((userCredential) => {
      // Signed in
      const user = userCredential.user;

      if (!user.emailVerified) {
        // this.props.onLIError("Email not verified");
        setLoginError("Email not verified");
        setLoading(false);
        return;
      }
    } catch (error) {
      // this.props.onLIError("The username and/or password are incorrect.");
      setLoginError("The username and/or password are incorrect.");
      setLoading(false);
      return;
    }

    try {
      let emailCheckResponse = await checkForEmail(response.access_token);
      props.isLoggedIn();

      localStorage.setItem("token", response.access_token);
      localStorage.setItem("accountId", emailCheckResponse.accountId);
      localStorage.setItem("contactId", emailCheckResponse.contactId);

      props.onASNameChange(
        emailCheckResponse.firstName,
        emailCheckResponse.accountId,
        emailCheckResponse.contactId
      );
      setLoading(false);
    } catch (err) {
      setLoginError(
        "Error finding account in salesforce.\nPlease contact support@livunltd.com"
      );
      setLoading(false);
      // this.props.onLIError("Please contact support@livunltd.com");

      return;
    }
  };
  const getSalesforceToken = async () => {
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      return { success: true, access_token: response.data };
    } catch (err) {
      console.log("getSalesforceToken err on login");
      return { success: false, error: err };
    }
  };

  const checkForEmail = async (token) => {
    try {
      let checkEmailResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/auth/email`,
        {
          params: {
            email: email,
            token: token,
          },
        }
      );

      // let checkEmailResponse = await axios.get(
      //   `https://na47.salesforce.com/services/data/v50.0/query/?q=SELECT+Id,Email,Name,AccountId+from+Contact+WHERE+Email='${this.props.email}'`, // url
      //   {
      //     //config
      //     params: {},
      //     headers: {
      //       Authorization: `Bearer ${this.props.token}`
      //       // "Access-Control-Allow-Methods": ["GET", "PUT", "POST"],
      //       // "Access-Control-Allow-Origin": "*"
      //     }
      //   }
      // );
      localStorage.removeItem("signedUp");
      return {
        success: true,
        numberOfRecords: checkEmailResponse.data.totalSize,
        firstName: checkEmailResponse.data.records[0].Name,
        accountId: checkEmailResponse.data.records[0].AccountId,
        contactId: checkEmailResponse.data.records[0].Id,
      };
    } catch (err) {
      return { success: false, error: err };
    }
  };

  const forgotPasswordPressed = async () => {
    if (email === "") {
      setForgotPasswordMessage("Please enter your email.");
      return;
    }

    try {
      let auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setForgotPasswordMessage("Forgot Password email sent.");
    } catch (err) {
      if (err.message.includes("auth/user-not-found")) {
        setForgotPasswordMessage("No account found with this email.");
      } else {
        setForgotPasswordMessage("Email not sent. Please verify your email.");
      }
      return;
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          // this.props.attemptLogin();
          await attemptLogin();
          // setLoading(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Group className="form_item">
            <Form.Control
              type="email"
              placeholder="EMAIL"
              value={email}
              onChange={(text) => {
                setForgotPasswordMessage("");
                setEmail(text.target.value);
                setLoading(false);
                setLoginError("");
                // this.props.onLIEmailChange(text.target.value);
              }}
              style={{
                textAlign: "center",
                minWidth: "300px",
                // width: "40vw",
                padding: "10px",
                height: "60px",
                border: "2px #EFEFEF solid",
                fontFamily: "proximanova_light",
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="form_item">
            <Form.Control
              type="password"
              placeholder="PASSWORD"
              value={password}
              onChange={(text) => {
                setForgotPasswordMessage("");

                setPassword(text.target.value);
                setLoading(false);
                setLoginError("");

                // this.props.onLIPasswordChange(text.target.value);
              }}
              style={{
                textAlign: "center",
                minWidth: "300px",
                // width: "40vw",
                padding: "10px",
                height: "60px",
                border: "2px #EFEFEF solid",
                // fontFamily: "proximanova_light"
              }}
            ></Form.Control>
          </Form.Group>
          {loginError !== "" && (
            <div
              style={{
                color: "#f34",
                marginTop: 10,
                paddingLeft: 10,
                marginBottom: 10,
                fontFamily: "proximanova_light",
                // width: "30%"
              }}
            >
              {loginError}
            </div>
          )}
          {forgotPasswordMessage !== "" && (
            <p style={{ fontFamily: "proximanova_light" }}>
              {forgotPasswordMessage}
            </p>
          )}
          {loading && (
            <div>
              <p style={{ fontFamily: "proximanova_light" }}>signing in...</p>
            </div>
          )}

          <button
            className="login_button"
            style={{
              marginTop: "10px",
              color: "white",
              fontSize: "24px",
              backgroundColor: "black",
              padding: "10px",
              width: "190px",
              letterSpacing: "2.5px",
              marginBottom: "5px",
            }}
            type="Submit"
          >
            LOGIN
          </button>
        </div>
      </Form>
      {/* </div> */}
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => {
            forgotPasswordPressed();
          }}
          style={{ border: "none", backgroundColor: "transparent" }}
        >
          <p
            style={{
              fontSize: "16px",
              color: "black",
              fontFamily: proximanova_light,
              textDecoration: "none",
            }}
          >
            FORGOT PASSWORD?
          </p>
        </button>
        <div style={{ width: "2rem" }}></div>
        <Link
          to={"/sign-up"}
          style={{
            color: "black",
            fontSize: "16px",
            fontFamily: proximanova_light,
            textDecoration: "none",
            marginRight: "40px",
          }}
        >
          CREATE PROFILE
        </Link>
      </div>
    </div>
  );
}

export default withRouter(connect(null, { addToken, onASNameChange })(Login));
