/** @format */

import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "../css/clients.css";
import ClientInfoInput from "./utils/ClientInfoInput";

function EditContactModal(props) {
  const [editedContact, setEditedContact] = useState({ ...props.contact });
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    if (props.creatingContact) {
      setEditedContact({
        Contact_Status__c: "Active",
        Active__c: true,
        AccountId: `${props?.entities?.[0].Id}`,
        Reason_for_New_Contact__c: "New Resident Flow Required",
      });
    } else {
      setEditedContact({ ...props.contact });
    }
    // eslint-disable-next-line
  }, [props?.contact?.Id]);

  const editContactField = (field, value) => {
    let updatedContact = { ...editedContact };

    if (field === "Contact_Status__c") {
      updatedContact.Active__c = value === "Active" ? true : false;
    }

    updatedContact[field] = value;
    setEditedContact({ ...updatedContact });
  };

  const confirmUpdate = async () => {

    setShowConfirm(true);
  };
  if (showConfirm) {
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={props.show}
        contentLabel="Inline Styles Modal Example"
        style={{
          overlay: {
            // backgroundColor: "transparent",
          },
          content: {
            border: "3px solid black",
            display: "flex",
            height: "50%",
            marginTop: "10%",
            color: "lightsteelblue",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "lightgray",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 id="modalTitle">
            Confirm Submit
          </h2>
          <div
            style={{
              display: "flex",
              width:"100%",
              justifyContent: "center",
              flexDirection: "row",
              marginTop: "30px",
            }}
          >
            <button
              style={{
                fontSize: 16,
                padding: "10px",
                backgroundColor: "white",
                border: "black 1px solid",
                borderRadius: "5px",
              }}
              onClick={() => {
                setShowConfirm(false);
              }}
            >
              Cancel
            </button>
            <button
              style={{
                marginLeft: "50px",
                fontSize: 16,
                padding: "10px",
                backgroundColor: "white",
                border: "black 1px solid",
                borderRadius: "5px",
                margin: "0px 0px 0px 20px",
              }}
              onClick={async () => {
                if (props.creatingContact) {
                  await props.onCreateNew(editedContact);
                } else {
                  await props.onUpdate(editedContact);
                }

                setShowConfirm(false);
              }}
            >
              {props.creatingContact ? "Create Contact" : "Update Contact"}
            </button>
          </div>
        </div>
      </ReactModal>
    );
  }
  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.onHide();
      }}
      ariaHideApp={false}
      isOpen={props.show}
      contentLabel="Inline Styles Modal Example"
      style={{
        overlay: {
          // backgroundColor: "transparent",
        },
        content: {
          height: "50%",
          marginTop: "10%",
          color: "lightsteelblue",
          backgroundColor: "lightgray",
          border: "3px solid black",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <h2 id="modalTitle">
          {props.creatingContact ? "Create Contact" : "Edit Contact"}
        </h2>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <ClientInfoInput
              style={{ marginRight: "40px" }}
              label={"*First Name:"}
              editContactField={(value) => editContactField("FirstName", value)}
              field={"FirstName"}
              contact={props.contact}
              editedContact={editedContact}
            />
            <ClientInfoInput
              label={"*Last Name:"}
              editContactField={(value) => editContactField("LastName", value)}
              field={"LastName"}
              contact={props.contact}
              editedContact={editedContact}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {props.creatingContact ? (
              <ClientInfoInput
                style={{ marginRight: "40px" }}
                inputType="dropdown"
                dropdownValues={props.entities}
                label={"Entity:"}
                editContactField={(value) =>
                  editContactField("AccountId", value)
                }
                field={"AccountId"}
                contact={props.contact}
                editedContact={editedContact}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  marginRight: "40px",
                }}
              >
                <span className="dataLabel">Entity Name:</span>:
                <span className="dataValue">
                  {editedContact?.Account?.Name ?? "N/A"}
                </span>
              </div>
            )}

            <ClientInfoInput
              label={"Unit:"}
              editContactField={(value) => editContactField("Unit__c", value)}
              field={"Unit__c"}
              contact={props.contact}
              editedContact={editedContact}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              //   justifyContent: ''
            }}
          >
            <ClientInfoInput
              style={{ marginRight: "40px" }}
              label={"Email:"}
              editContactField={(value) => editContactField("Email", value)}
              field={"Email"}
              contact={props.contact}
              editedContact={editedContact}
            />

            <ClientInfoInput
              label={"Phone:"}
              editContactField={(value) => editContactField("Phone", value)}
              field={"Phone"}
              contact={props.contact}
              editedContact={editedContact}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              //   justifyContent: ''
            }}
          >
            <ClientInfoInput
              style={{ marginRight: "40px" }}
              inputType={"Date"}
              label={"Lease Start Date:"}
              editContactField={(value) => editContactField("LDS__c", value)}
              field={"LDS__c"}
              contact={props.contact}
              editedContact={editedContact}
            />
            <ClientInfoInput
              inputType={"Date"}
              label={"Lease End Date:"}
              editContactField={(value) => editContactField("LED__c", value)}
              field={"LED__c"}
              contact={props.contact}
              editedContact={editedContact}
            />

          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",

              width: props.creatingContact ? "100%" : "50%",
            }}
          >
            <ClientInfoInput
              style={{ marginRight: props.creatingContact ? "40px" : "20px" }}
              inputType="dropdown"
              dropdownValues={[
                { Id: "Active", Name: "Active" },
                { Id: "Inactive", Name: "Inactive" },
              ]}
              label={"Status:"}
              editContactField={(value) =>
                editContactField("Contact_Status__c", value)
              }
              field={"Contact_Status__c"}
              contact={props.contact}
              editedContact={editedContact}
            />

            {props.creatingContact && (
              <ClientInfoInput
                inputType="dropdown"
                dropdownValues={[
                  {
                    Id: "New Resident Flow Required",
                    Name: "New Resident Flow Required",
                  },
                  { Id: "App Access", Name: "App Access" },
                  { Id: "Other", Name: "Other" },
                ]}
                label={"Reason for New Contact:"}
                editContactField={(value) =>
                  editContactField("Reason_for_New_Contact__c", value)
                }
                field={"Reason_for_New_Contact__c"}
                contact={props.contact}
                editedContact={editedContact}
              />
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <button
            style={{
              fontSize: 16,
              padding: "10px",
              backgroundColor: "white",
              border: "black 1px solid",
              borderRadius: "5px",
              margin: "20px 0px 20px 0px",
            }}
            onClick={() => {
              setEditedContact({ ...props.contact });
              props.onHide();
            }}
          >
            Close Modal
          </button>
          <button
            style={{
              fontSize: 16,
              padding: "10px",
              backgroundColor: "white",
              border: "black 1px solid",
              borderRadius: "5px",
              margin: "20px 0px 20px 20px",
            }}
            onClick={confirmUpdate}
          >
            {props.creatingContact ? "Create Contact" : "Update Contact"}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default EditContactModal;
