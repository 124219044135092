/** @format */

import {
  AS_ATTEMPTED,
  AS_NAME_CHANGED,
  AS_EMAIL_CHANGED,
  AS_PASSWORD1_CHANGED,
  AS_PASSWORD2_CHANGED,
  AS_CLEARALL,
  AS_ERROR,
} from "../actions/types";

const AS_SUCCESS = "AS_SUCCESS";
const AS_FAIL = "AS_FAIL";

const INITIAL_STATE = {
  email: "",
  password1: "",
  password2: "",
  error: "",
  token: "",
  displayName: "",
  accountId: "",
  contactId: "",
  loading: false,
};

export const attemptAccountSetup = () => {
  return {
    type: AS_ATTEMPTED,
  };
};

export const onASNameChange = (displayName, accountId, contactId) => {
  return {
    type: AS_NAME_CHANGED,
    payload: { displayName, accountId, contactId },
  };
};

export const onASEmailChange = (text) => {
  return {
    type: AS_EMAIL_CHANGED,
    payload: text,
  };
};

export const onASPassword1Change = (text) => {
  return {
    type: AS_PASSWORD1_CHANGED,
    payload: text,
  };
};

export const onASPassword2Change = (text) => {
  return {
    type: AS_PASSWORD2_CHANGED,
    payload: text,
  };
};

export const onASClearFields = () => {
  return {
    type: AS_CLEARALL,
    payload: "clear all",
  };
};

export const onASError = (err) => ({
  type: AS_ERROR,
  payload: err,
});

let reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AS_ATTEMPTED:
      return { ...state, error: "", loading: true };
    case AS_SUCCESS:
      return INITIAL_STATE;
    case AS_FAIL:
      return INITIAL_STATE;
    case AS_EMAIL_CHANGED:
      return { ...state, email: action.payload, loading: false, error: "" };
    case AS_PASSWORD1_CHANGED:
      return { ...state, password1: action.payload, loading: false, error: "" };
    case AS_PASSWORD2_CHANGED:
      return { ...state, password2: action.payload, loading: false, error: "" };
    case AS_NAME_CHANGED:
      return {
        ...state,
        displayName: action.payload.displayName,
        accountId: action.payload.accountId,
        contactId: action.payload.contactId,
        error: "",
      };
    case AS_CLEARALL:
      return INITIAL_STATE;
    case AS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default reducer