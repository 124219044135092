/** @format */

import React, { PureComponent } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
// import ChangePasswordForm from "./ChangePasswordForm";
import "../css/style.css";
import { GLOBAL_DOMAIN } from "../actions/types";

class Account extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      showChangePassword: false,
      changePasswordSuccess: false,
    };
  }

  componentDidMount = async () => {
    try {
      let accountInfo = await axios.get(`${GLOBAL_DOMAIN}/api/auth/account`, {
        params: {
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });
      this.setState({ userInfo: accountInfo.data.records[0] });
    } catch (error) {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      localStorage.setItem("token", response.data);
      let accountInfo = await axios.get(`${GLOBAL_DOMAIN}/api/auth/account`, {
        params: {
          contactId: localStorage.getItem("contactId"),
          token: localStorage.getItem("token"),
        },
      });
      this.setState({ userInfo: accountInfo.data.records?.[0] });
    }
  };

  handleShow = () => {
    this.setState({
      showChangePassword: !this.state.showChangePassword,
      changePasswordSuccess: false,
    });
  };

  handleClose = (passwordChanged) => {
    this.setState({ showChangePassword: false });
  };

  changePasswordSuccess = () => {
    this.setState({ changePasswordSuccess: true });
    setTimeout(() => {
      this.setState({ changePasswordSuccess: false });
    }, 2000);
  };

  render() {
    const { Name, Email, MC_Entity_Name__c } = this?.state?.userInfo;
    // let Name = "aName";
    // let Email = "Email";
    // let MC_Entity_Name__c = "MCName";
    return (
      <Container
        style={{ backgroundColor: "black", color: "white", margin: "10px 0px" }}
      >
        <Row>
          <Col
            style={{
              display: this.state.showChangePassword ? "" : "none",
              backgroundColor: "black",
            }}
          >
            {/* <ChangePasswordForm
              handleClose={this.handleClose}
              email={this.state.userInfo.Email}
              successfulChange={this.changePasswordSuccess}
            /> */}
          </Col>
          <Col
            className="info"
            style={{
              display: !this.state.showChangePassword ? "" : "none",
              backgroundColor: "black",
              padding: "15px",
            }}
          >
            <div>
              NAME: <p>{Name && Name.toUpperCase()}</p>
            </div>
            <div>
              EMAIL: <p>{Email && Email.toUpperCase()}</p>
            </div>
            <div>
              RESIDENCE: <p>{MC_Entity_Name__c}</p>
            </div>

            {this.state.changePasswordSuccess && (
              <p
                style={{
                  backgroundColor: "green",
                  color: "white",
                  textAlign: "center",
                }}
              >
                PASSWORD CHANGED
              </p>
            )}
            {/* <Button
              variant="none"
              style={{
                color: "white",
                border: "2px white solid",
                marginRight: "10px",
              }}
              onClick={() => this.handleShow()}
            >
              CHANGE PASSWORD
            </Button> */}
            <Button
              href="/login"
              variant="none"
              onClick={() => {
                localStorage.removeItem("contactId");
                localStorage.removeItem("accountId");
                localStorage.removeItem("token");
                this.props.onASClearFields();
              }}
              style={{
                color: "white",
                border: "2px white solid",
              }}
            >
              LOGOUT
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Account;
