/** @format */

import React from "react";

export default function LabelValueSnippet({ label, value, style }) {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        marginBottom: "15px",
  
      }}
    >
      <span className="dataLabel">{label}</span>:
      <span className="dataValue">{value ?? "N/A"}</span>
    </div>
  );
}
