import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Root from "./components/Root";
import store from "./store";
// import history from "./history";

function App() {
  return (
    <Provider store={store}>
      {/* <Router history={history}> */}
      <Router>
        <Root />
      </Router>
    </Provider>
  );
}

export default App;
