/** @format */

import React from "react";

export default function ClientInfoInput({
  label,
  inputType,
  contact,
  editedContact,
  editContactField,
  field,
  style,
  placeholder,
  dropdownValues,
}) {
  const renderInput = () => {
    switch (inputType) {
      case "dropdown":
        return (
          <select
            style={{
              marginLeft: "5px",
              flex: 10,
              padding: "10px",
              borderRadius: "10px",
              border: "solid black 1px",
            }}
            onChange={(e) => {
              editContactField(e.target.value);
            }}
            defaultValue={contact?.[field]}
          >
            {dropdownValues.map((item) => {
              return (
                <option key={item.Id} value={item.Id} >
                  {item.Name}
                </option>
              );
            })}
          </select>
        );
      default:
        return (
          <input
            type={inputType ?? "text"}
            maxLength={50}
            style={{
              flex: 10,
              height: "20px",
              padding: "10px",
              borderRadius: "10px",
              border: "solid black 1px",
              minWidth: "100px"
            }}
            onChange={(e) => {
              editContactField(e.target.value);
            }}
            placeholder={contact?.[field] ?? placeholder}
            value={`${editedContact?.[field] ?? ""}`}
          />
        );
    }
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        ...style,
      }}
    >
      <p style={{ color: "black", marginRight: "5px" }} className="dataLabel">
        {label}
      </p>
      {renderInput()}
    </div>
  );
}
