import React, { PureComponent } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom/cjs/react-router-dom";
class Terms extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div
          style={{
            position: "relative",
            width: "100%",
            textAlign: "center",
            fontFamily: "proximanova_light",
            marginTop: 20,
          }}
        >
          <Link
            to={"/"}
            className="privacyBackButton"
            style={{
              position: "absolute",
              left: "0px",
              fontSize: "16px",
              fontFamily: "proximanova_light",
            }}
          >
            &lt; Return
          </Link>

          <p
            style={{
              fontSize: "16px",
              margin: 0,
              fontWeight: "bold",
              fontFamily: "proximanova_light",
            }}
          >
            Terms of Use
          </p>
        </div>
        <div className="PrivacyContainer">
          <p style={{ fontWeight: "bold" }}>I. Acceptance of Terms</p>
          <p>
            These Terms of Use (the “Terms”) govern your access or use of LIV
            unLtd, LLC’s website located at{" "}
            <a
              href="https://propertyportal.livunltd.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://propertyportal.livunltd.com/terms
            </a>{" "}
            (the “Website”). Please read these Terms and our Privacy Policy
            located at{" "}
            <a
              href="https://propertyportal.livunltd.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://propertyportal.livunltd.com/privacy
            </a>{" "}
            (the “Privacy Policy”) carefully before use of the Website, as they
            constitute a legal agreement between you and LIV unLtd, LLC
            governing your access and use of the Website and the services
            accessible via the Website (collectively, the “Services”). Your use
            of the Services will be deemed to be your acceptance of these Terms.
            If you do not agree with these Terms, do not use the Services.
          </p>
          <p style={{ fontWeight: "bold" }}>
            <u>IMPORTANT NOTICE REGARDING ARBITRATION</u>: THESE TERMS CONTAIN A
            MANDATORY ARBITRATION AGREEMENT, REQUIRING YOU TO RESOLVE ANY
            DISPUTE BETWEEN YOU AND LIV unLtd, LLC THROUGH FINAL AND BINDING
            ARBITRATION RATHER THAN IN COURT, AND REQUIRING YOU TO FOREGO JURY
            TRIALS, CLASS ACTIONS, AND ALL OTHER TYPES OF COURT PROCEEDINGS OF
            ANY AND EVERY KIND. YOU WILL BE BOUND BY THIS ARBITRATION AGREEMENT,
            UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT BY FOLLOWING THE
            OPT-OUT PROCEDURES SET FORTH BELOW. BY ENTERING THIS AGREEMENT, YOU
            EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE
            TERMS OF THE ARBITRATION AGREEMENT AND HAVE TAKEN TIME TO CONSIDER
            THE CONSEQUENCES OF THIS IMPORTANT DECISION.
          </p>
          <p style={{ fontWeight: "bold" }}>II. Arbitration Agreement</p>
          <p style={{ fontWeight: "bold" }}>
            <u>Mandatory Binding Arbitration of Disputes.</u> By agreeing to
            these Terms, you agree that any dispute, claim or controversy
            arising out of or relating to these Terms or the breach,
            termination, enforcement, interpretation or validity thereof or the
            use of the Services (collectively, “Disputes”) will be resolved
            solely by binding, individual arbitration and not in a court of law
            in any jurisdiction and not in a class, representative or
            consolidated action or proceeding, as set forth further below.
          </p>
          <p>
            You and LIV unLtd, LLC agree that the U.S. Federal Arbitration Act
            (“FAA”) governs the interpretation, applicability, enforceability or
            formation of this Arbitration Agreement, including any claim that
            all or any part of this Arbitration Agreement is void or voidable,
            notwithstanding any choice of law or other provision in these Terms.
            It is the intent of the parties that the FAA and the American
            Arbitration Association (“AAA”) Rules described below shall preempt
            all state laws to the fullest extent permitted by law. If the FAA
            and AAA Rules are found to not apply to any issue that arises under
            this Arbitration Agreement or the enforcement thereof, then that
            issue shall be resolved under the laws of the State of Delaware,
            without regard to its conflict of laws provisions.
          </p>
          <p>
            You and LIV unLtd, LLC further agree that the arbitrator
            (“Arbitrator”), and not any federal, state, or local court or
            agency, shall have exclusive authority to resolve any disputes
            concerning the interpretation, applicability, enforceability or
            formation of this Arbitration Agreement. The Arbitrator shall also
            be responsible for determining all threshold arbitrability issues,
            including issues relating to whether these Terms are unconscionable
            or illusory and any defense to arbitration, including waiver, delay,
            laches, or estoppel.
          </p>
          <p style={{ fontWeight: "bold" }}>
            <u>Class Action Waiver.</u> YOU AND LIV unLtd, LLC AGREE THAT EACH
            PARTY IS WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN
            ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. UNLESS BOTH
            YOU AND LIV unLtd, LLC AGREE IN WRITING, YOU AND LIV unLtd, LLC MAY
            BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
            CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
            CLASS, COLLECTIVE, CONSOLIDATED, OR REPRESENTATIVE PROCEEDING.
            Further, if the parties’ dispute is resolved through arbitration,
            the Arbitrator may not consolidate another person’s claims with your
            claims, and may not otherwise preside over any form of a
            representative or class proceeding. If the foregoing sentence is
            found to be unenforceable, then the entirety of this Arbitration
            Agreement section shall be null and void. This arbitration provision
            shall survive termination of these Terms.
          </p>
          <p>
            <u>Exceptions and Opt-Out Procedures.</u> As limited exceptions to
            the mandatory arbitration provision above: (i) you may seek to
            resolve a Dispute in small claims court if it qualifies; and (ii) we
            each retain the right to seek injunctive or other equitable relief
            from a court to prevent (or enjoin) the actual or threatened
            infringement or misappropriation of our intellectual property
            rights.
          </p>
          <p>
            You can choose to reject this Arbitration Agreement by sending us a
            written opt-out notice (“Opt-Out Notice”) within thirty (30) days
            following the date you first agree to these Terms by mail at
            LIVunLtd, LLC, 11155 Dolfield Boulevard, Suite 216, Owings Mills, MD
            21117 or by email at privacy@amenitycollective.com. If mailed, the
            Opt-Out Notice must be postmarked no later than 30 days following
            the date you first agree to these Terms. To be effective, the
            Opt-Out Notice must contain your name, address, and signature. If
            you opt-out of the Arbitration Agreement, all other parts of these
            Terms will continue to apply to you. Opting out of this Arbitration
            Agreement has no effect on any previous, other, or future
            arbitration agreements that you may have with LIV unLtd, LLC.
          </p>
          <p>
            <u>Conducting Arbitration and Arbitration Rules.</u> The arbitration
            will be administered by the AAA under its Consumer Arbitration Rules
            and any supplementary rules (the “AAA Rules”) then in effect, except
            as modified by these Terms. The AAA Rules are available at
            www.adr.org or by calling the AAA at 1-800-778-7879. The rules of
            the arbitral forum will govern all aspects of this arbitration,
            except to the extent those rules conflict with these Terms.
          </p>
          <p>
            A party who wishes to start arbitration must submit a written Demand
            for Arbitration to AAA and give notice to the other party as
            specified in the AAA Rules. The AAA provides a form Demand for
            Arbitration at www.adr.org. You can contact the AAA for more
            information on how to commence an arbitration proceeding at
            www.adr.org or 1-800-778-7879.
          </p>
          <p>
            If your claim is for U.S. $10,000 or less, you may choose whether
            the arbitration will be conducted solely on the basis of documents
            submitted to the Arbitrator, through a telephonic or
            video-conference hearing, or in-person hearing as established by the
            AAA Rules. If your claim exceeds U.S. $10,000, the right to a
            hearing will be determined by the AAA Rules. Any arbitration
            hearings will take place in the county (or parish) where you live,
            unless we both agree to a different location.
          </p>
          <p>
            <u>Arbitration Costs.</u> Payment of all filing, administration and
            arbitrator fees will be governed by the AAA Rules. LIV unLtd, LLC
            will pay for all filing, administration and arbitrator fees and
            expenses if your Dispute is for less than $10,000, unless the
            Arbitrator finds your Dispute frivolous. If LIV unLtd, LLC prevails
            in arbitration, it will pay all of its attorneys’ fees and costs and
            will not seek to recover them from you.
          </p>
          <p>
            <u>Changes to Arbitration Agreement.</u> Notwithstanding the
            provisions of Section XVI “Changes to Terms” below, if LIV unLtd,
            LLC changes any of the terms of this Section II “Arbitration
            Agreement” after the date you first accepted these Terms (or
            accepted any subsequent changes to these Terms), you may reject any
            such change by sending us written notice of such rejection within 30
            days of the date such change became effective, as indicated in the
            “Last Modified” date below. The written notice must be provided
            either by mail at LIVunLtd, LLC, 11155 Dolfield Boulevard, Suite
            216, Owings Mills, MD 21117 or by email at
            privacy@amenitycollective.com. To be effective, your notice must
            include your full name and clearly indicate your intent to reject
            changes to this Arbitration Agreement. By rejecting any change, you
            are agreeing that you will arbitrate any Dispute between you and LIV
            unLtd, LLC in accordance with the terms of this Section II
            “Arbitration Agreement” as of the date you first accepted these
            Terms (or accepted any subsequent changes to these Terms).
          </p>
          <p style={{ fontWeight: "bold" }}>
            III. Intellectual Property Rights
          </p>
          <p>
            All information, text, photographs, graphics, images, videos, logos,
            data, software and other materials including LIV unLtd, LLC
            trademarks, service marks and copyrights (collectively the
            “Content”) found through the Services are owned or licensed by LIV
            unLtd, LLC and may not be used, copied, broadcast, distributed,
            uploaded, posted or shared in any way without the express written
            consent of LIV unLtd, LLC.
          </p>
          <p>
            LIV unLtd, LLC expressly reserves all intellectual property rights
            in the Content. Nothing contained on the Website grants or should be
            construed as granting any license or right to use the Content for
            any purpose without the express written consent of LIV unLtd, LLC.
          </p>
          <p style={{ fontWeight: "bold" }}>
            IV. Content Ownership, Responsibility, and Removal
          </p>
          <p>
            <u>Definitions.</u> For purposes of these Terms: (i) “Content” means
            text, graphics, images, music, software, audio, video, works of
            authorship of any kind, and information or other materials that are
            posted, generated, provided or otherwise made available through the
            Services; and (ii) “User Content” means any Content that users of
            the Services (including you) provide to be made available through
            the Services.
          </p>
          <p>
            <u>Our Content Ownership.</u> LIV unLtd, LLC does not claim any
            ownership rights in any User Content and nothing in these Terms will
            be deemed to restrict any rights that you may have to use and
            exploit your User Content. Subject to the foregoing, LIV unLtd, LLC
            and its licensors exclusively own all right, title and interest in
            and to the Services and Content, including all associated
            intellectual property rights. You acknowledge that the Services and
            Content are protected by copyright, trademark, and other laws of the
            United States and foreign countries. You agree not to remove, alter
            or obscure any copyright, trademark, service mark or other
            proprietary rights notices incorporated in or accompanying the
            Services or Content.
          </p>
          <p>
            <u>Rights in User Content Generated by You.</u> By making any User
            Content available through the Services, you hereby grant to LIV
            unLtd, LLC a non-exclusive, transferable, worldwide, royalty- free
            license, with the right to sublicense, to use, copy, modify (for
            formatting purposes only), distribute, publicly display, and
            publicly perform your User Content in connection with operating and
            providing the Services to you.
          </p>
          <p>
            <u>Your Responsibility for User Content.</u> You are solely
            responsible for all your User Content. You represent and warrant
            that you own all your User Content or you have all rights that are
            necessary to grant us the license rights in your User Content under
            these Terms. You also represent and warrant that neither your User
            Content, nor your use and provision of your User Content to be made
            available through the Services, nor any use of your User Content by
            LIV unLtd, LLC on or through the Services will infringe,
            misappropriate or violate a third party’s intellectual property
            rights, or rights of publicity or privacy, or result in the
            violation of any applicable law or regulation.
          </p>
          <p>
            <u>Removal of User Content.</u> User Content may continue to exist
            on the Services. We are not responsible or liable for the removal or
            deletion of (or the failure to remove or delete) any of your User
            Content.
          </p>
          <p>
            <u>Rights in Content Granted by LIV unLtd, LLC.</u> Subject to your
            compliance with these Terms, LIV unLtd, LLC grants to you a limited,
            non-exclusive, non-transferable license, with no right to
            sublicense, to access and use the Content solely in connection with
            your permitted use of the Services and solely for your personal and
            non-commercial purposes.
          </p>
          <p style={{ fontWeight: "bold" }}>V. Prohibited Uses</p>
          <p>
            You may use the Services only for lawful purposes and in accordance
            with these Terms. You may not use the Services: to violate any
            applicable law or regulation; to infringe the rights of any third
            party, including without limitation, intellectual property, privacy,
            and contractual rights; for any purpose that is unlawful or
            prohibited by these Terms; to cause harm or damage to any person or
            entity; to interfere with the proper operation of the Services; or
            to upload, post or transmit any material that violates any law,
            infringes on the rights of any third party or contains defamatory,
            libelous, abusive, threatening, obscene or otherwise objectionable
            material (as determined by LIV unLtd, LLC in its sole discretion).
          </p>
          <p style={{ fontWeight: "bold" }}>VI. Third Party Websites</p>
          <p>
            The Services may contain links to other websites or resources. These
            links are for your convenience only and are not under our control
            and are not subject to LIV unLtd, LLC’s Privacy Policy. We recommend
            that you check the privacy policies of each such website to
            determine how your information will be used.
          </p>
          <p style={{ fontWeight: "bold" }}>VII. Privacy Policy</p>
          <p>
            All information we collect through the Services is subject to our
            Privacy Policy. By using the Services, you consent to all actions
            taken by LIV unLtd, LLC with respect to your information in
            compliance with the Privacy Policy. For a detailed description of
            LIV unLtd, LLC’s information collection practices, please see our
            Privacy Policy, which is available on our website{" "}
            <a
              href="https://propertyportal.livunltd.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://propertyportal.livunltd.com/privacy
            </a>
            .
          </p>
          <p style={{ fontWeight: "bold" }}>VIII. Disclaimers</p>
          <p>
            THE MATERIALS AND SERVICES PROVIDED ON THE WEBSITE ARE PROVIDED “AS
            IS” WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-
            INFRINGEMENT OF INTELLECTUAL PROPERTY.
          </p>
          <p>
            LIV unLtd, LLC DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
            REGARDING THE AVAILABILITY, USE, TIMELINESS, SECURITY, VALIDITY,
            ACCURACY, OR RELIABILITY OF, OR THE RESULTS OF THE USE OF, OR
            OTHERWISE RESPECTING, THE CONTENT OF THE WEBSITE.
          </p>
          <p>
            ANY MATERIAL VIEWED, DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
            USE OF THE WEBSITE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU
            ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
            LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL, OR
            USE OF THE WEBSITE. INFORMATION PUBLISHED AT THIS SITE MAY REFER TO
            PRODUCTS, PROGRAMS OR SERVICES THAT ARE NOT AVAILABLE IN YOUR
            JURISDICTION.
          </p>
          <p style={{ fontWeight: "bold" }}>IX. Limitation of Liability</p>
          <p>
            IN NO EVENT WILL LIV unLtd, LLC BE LIABLE FOR ANY DAMAGES WHATSOEVER
            (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS,
            LOST DATA OR BUSINESS INTERRUPTION, OR CAUSED BY OR RELATED TO
            ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS, DELAY IN OPERATION OR
            TRANSMISSION, COMPUTER VIRUS, LINE FAILURE, AND ALL OTHER DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES)
            ARISING OUT OF THE USE, INABILITY TO USE, OR THE RESULTS OF USE OF
            THE WEBSITE, ANY WEBSITES LINKED TO THE WEBSITE, OR THE MATERIALS OR
            INFORMATION OR SERVICES CONTAINED AT ANY OR ALL SUCH WEBSITES,
            WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY
            AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF
            YOUR USE OF THE MATERIALS, INFORMATION OR SERVICES FROM THE WEBSITE
            RESULTS IN THE NEED FOR SERVICING, REPAIR OR CORRECTION OF EQUIPMENT
            OR DATA, YOU ASSUME ALL COSTS THEREOF. APPLICABLE LAW MAY NOT ALLOW
            THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES,
            SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
            NOTWITHSTANDING THE FOREGOING, LIV unLtd, LLC’S TOTAL LIABILITY TO
            YOU FOR ALL LOSSES, DAMAGES, AND CAUSES OF ACTION, INCLUDING BUT NOT
            LIMITED TO THOSE BASED ON CONTRACT, TORT OR OTHERWISE, ARISING OUT
            OF YOUR USE OF THIS WEBSITE, ITS CONTENT OR LINKS, SHALL NOT EXCEED
            THE AMOUNT YOU PAID TO ACCESS THIS WEBSITE.
          </p>
          <p>
            THE LIMITATIONS AND DISCLAIMERS IN THESE TERMS DO NOT PURPORT TO
            LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW, INCLUDING NEW JERSEY LAW.
          </p>
          <p style={{ fontWeight: "bold" }}>
            X. Governing Law and Jurisdiction
          </p>
          <p>
            These Terms and the Privacy Policy shall be construed under the laws
            of the State of Delaware, which shall control without regard to its
            conflict of laws provisions. Any action arising out of or relating
            to these Terms shall be commenced and concluded only in the State of
            Delaware.
          </p>
          <p style={{ fontWeight: "bold" }}>XI. Termination</p>
          <p>
            LIV unLtd, LLC, in its sole discretion, may terminate or restrict
            your use or access to the Services for any reason, including,
            without limitation, if LIV unLtd, LLC believes you have violated or
            acted inconsistently with these Terms. Upon any termination,
            discontinuation, or cancellation of the Services, the following
            provisions of these Terms will survive: Sections II, IV, VIII, IX,
            and X.
          </p>
          <p style={{ fontWeight: "bold" }}>XII. Waiver</p>
          <p>
            LIV unLtd, LLC’s failure to enforce any portion of the Terms shall
            not constitute a waiver of any of its rights under these Terms.
          </p>
          <p style={{ fontWeight: "bold" }}>XIII. Severability</p>
          <p>
            Except as set forth in Section II, “Class Action Waiver,” in the
            event that any provision or part of any provision in these Terms is
            found to be unenforceable or unlawful for any reason, the
            unenforceable or unlawful provision or portion thereof shall be
            severed from these Terms; severance of the unenforceable or unlawful
            provision or portion thereof shall have no impact whatsoever on the
            remainder of the Arbitration Agreement or the parties’ ability to
            compel arbitration of any remaining claims on an individual basis
            pursuant to the Arbitration Agreement; and to the extent that any
            claims must therefore proceed on a class, collective, consolidated,
            or representative basis, such claims must be litigated in a civil
            court of competent jurisdiction and not in arbitration, and the
            parties agree that litigation of those claims shall be stayed
            pending the outcome of any individual claims in arbitration.
          </p>
          <p style={{ fontWeight: "bold" }}>XIV. Indemnification</p>
          <p>
            YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS LIV unLtd, LLC AND
            ITS PRESENT AND FORMER OFFICERS, EMPLOYEES, SHAREHOLDERS, MEMBERS,
            DIRECTORS, FRANCHISEES, AGENTS, SERVANTS, REPRESENTATIVES, PARENTS,
            AFFILIATES, SUCCESSORS, AND ASSIGNS FROM AND AGAINST ANY ACTUAL OR
            THREATENED CLAIMS, ACTIONS OR DEMANDS, LIABILITIES AND SETTLEMENTS
            (INCLUDING, WITHOUT LIMITATION, REASONABLE LEGAL AND ACCOUNTING
            FEES) RESULTING (OR ALLEGED TO RESULT) FROM YOUR USE OF THE WEBSITE
            IN ANY MANNER THAT VIOLATES OR IS ALLEGED TO VIOLATE ANY APPLICABLE
            LAW OR THESE TERMS.
          </p>
          <p style={{ fontWeight: "bold" }}>XV. Delays and Accessibility</p>
          <p>
            The Services may be subject to limitations, delays, failures or
            other problems associated with the use of the Internet and
            electronic communications. From time to time, we may restrict access
            to all or some parts of the Services. LIV unLtd, LLC is not liable
            if the Services are unavailable at any time for any reason.
          </p>
          <p style={{ fontWeight: "bold" }}>XVI. Changes to Terms</p>
          <p>
            From time to time, we may change these Terms to accommodate new
            technologies, industry practices, regulatory requirements or for
            other purposes in our sole discretion. You are advised to check the
            Terms regularly, as continued use is deemed to be approval of and
            consent to all such changes.
          </p>
          <p style={{ fontWeight: "bold" }}>XVII. Changes to Website</p>
          <p>
            We may update the content on the Website from time to time, but its
            content is not necessarily complete or up-to-date. The materials and
            services at the Website may be out of date, and LIV unLtd, LLC makes
            no commitment to update the materials and services at this Website.
          </p>
          <p style={{ fontWeight: "bold" }}>XVIII. Entire Agreement</p>
          <p>
            These Terms and the Privacy Policy constitute the entire integrated
            and exclusive agreement between you and LIV unLtd, LLC with respect
            to the Services and supersede all prior understandings, agreements,
            representations and warranties, both written and oral, with respect
            to the Services.
          </p>
          <p style={{ fontWeight: "bold" }}>XIX. Comments and Questions</p>
          <p>
            If you have a comment or question about these Terms, please contact
            us at{" "}
            <a
              href="mailto:privacy@amenitycollective.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy@amenitycollective.com
            </a>
            .
          </p>
          <p>Last Modified: September 13, 2024.</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(Terms);
