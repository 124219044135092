import { combineReducers } from "redux";

import AuthReducer from "./AuthReducer";
import AccountSetupReducer from "./AccountSetupReducer";

// export * from "./AuthReducer";
export default combineReducers({
  auth: AuthReducer,
  account: AccountSetupReducer
});
