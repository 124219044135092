/** @format */

import ReactModal from "react-modal";

import React, { useEffect, useState } from "react";
import ClientInfoInput from "./utils/ClientInfoInput";

function FilterModal(props) {
  const [filters, setFilters] = useState({});

  useEffect(() => {}, []);

  const editFilters = (filter, value) => {
    if (value === "None") {
      delete filters[filter];
    } else {
      let newFilters = { ...filters };
      newFilters[filter] = value;
      setFilters(newFilters);
    }
  };

  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.onHide();
      }}
      ariaHideApp={false}
      isOpen={props.show}
      contentLabel="Inline Styles Modal Example"
      style={{
        content: {
          display: "flex",
          height: "50%",
          marginTop: "10%",
          color: "lightsteelblue",
          backgroundColor: "lightgray",
          border: "3px solid black",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
      }}
    >
      <h2 id="modalTitle">Filter</h2>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <ClientInfoInput
          inputType="dropdown"
          dropdownValues={[
            { Id: "None", Name: "-" },
            { Id: "Active", Name: "Active" },
            { Id: "Inactive", Name: "Inactive" },
          ]}
          label={"Status:"}
          editContactField={(value) => {
            editFilters("Contact_Status__c", value);
          }}
          field={"Contact_Status__c"}
          contact={filters}
          editedContact={filters}
        />

        <ClientInfoInput
          inputType="dropdown"
          dropdownValues={[{ Id: "None", Name: "-" }, ...props.entities]}
          label={"Entity:"}
          editContactField={(value) => editFilters("AccountId", value)}
          field={"AccountId"}
          contact={filters}
          editedContact={filters}
        />

        <ClientInfoInput
          inputType="dropdown"
          dropdownValues={[
            { Id: "None", Name: "-" },

            {
              Id: "New Resident Flow Required",
              Name: "New Resident Flow Required",
            },
            { Id: "App Access", Name: "App Access" },
            { Id: "Other", Name: "Other" },
          ]}
          label={"Reason for New Contact:"}
          editContactField={(value) =>
            editFilters("Reason_for_New_Contact__c", value)
          }
          field={"Reason_for_New_Contact__c"}
          contact={filters}
          editedContact={filters}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <button
          style={{
            fontSize: 16,
            padding: "10px",
            backgroundColor: "white",
            border: "black 1px solid",
            borderRadius: "5px",
            margin: "20px 0px 20px 0px",
          }}
          onClick={props.onHide}
        >
          Close Filter
        </button>
        <button
          style={{
            fontSize: 16,
            padding: "10px",
            backgroundColor: "white",
            border: "black 1px solid",
            borderRadius: "5px",
            margin: "20px 0px 20px 20px",
          }}
          onClick={() => props.applyFilter(filters)}
        >
          Apply Filter
        </button>
      </div>
    </ReactModal>
  );
}

export default FilterModal;
