/** @format */

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GLOBAL_DOMAIN } from "../actions/types";
import proximanova_light from "../assets/fonts/Proxima_Nova_Alt_Light.otf";
import "../css/requestForm.css";
import "../css/style.css";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { addToken } from "../actions";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from "@firebase/auth";

function SignUp(props) {
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountSetupError, setAccountSetupError] = useState("");
  const [accountCreated, setAccountCreated] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const attemptCreateAccount = async () => {
    setLoading(true);

    // this.props.attemptAccountSetup();
    // 1) Check if e-mail is null
    if (email === "") {
      setAccountSetupError("Please enter a valid email.");
      // this.props.onASError("Please enter a valid email.");
      setLoading(false);
      setTimeout(() => {
        setAccountSetupError("");
      }, 3000);
      return;
    }

    let response = { access_token: token };

    if (!token) {
      try {
        response = await getSalesforceToken();
        // 1a) Token success? If not, post alert. If yes, add to reducer
        if (!response.success) {
          setAccountSetupError("Error Verifying Account");
          setLoading(false);
          // setLoading(false);
          return;
        } else {
          props.addToken(response.access_token);
          localStorage.setItem("token", response.access_token);
        }
      } catch (err) {
        setAccountSetupError("Error Signing Up");
        setLoading(false);
        // setLoading(false);
        // props.onLIError("Error Logging In");
        return;
      }
    }

    let emailCheckResponse = await checkForEmail(response.access_token);

    // Error on call
    if (!emailCheckResponse.success) {
      setAccountSetupError("There was an error accessing your email.");
      setLoading(false);
      return;
    }
    // Call was successful
    else if (emailCheckResponse.numberOfRecords === 0) {
      setAccountSetupError("The email entered is not in our system.");
      setLoading(false);
      setTimeout(() => {
        setAccountSetupError("");
      }, 3000);
      return;
    } else if (!emailCheckResponse.portalAccess) {
      setAccountSetupError(
        "You do not have access. Please contact your admin."
      );
      setLoading(false);
      setTimeout(() => {
        setAccountSetupError("");
      }, 3000);
      return;
    }

    if (password1 === "" || password2 === "") {
      setAccountSetupError("Password cannot be blank.");
      setLoading(false);
      setTimeout(() => {
        setAccountSetupError("");
      }, 3000);
      return;
    }

    // Check if pw1 matches pw2
    if (password1 !== password2) {
      setAccountSetupError("Passwords must match.");
      setLoading(false);
      setTimeout(() => {
        setAccountSetupError("");
      }, 3000);
      return;
    }

    let auth = getAuth();
    try {
      await createUserWithEmailAndPassword(
        auth,
        email,
        password1
      );
      sendEmailVerification(auth.currentUser);

    } catch (error) {
      setAccountSetupError(`Error Creating Account.`);
      setLoading(false);
      setTimeout(() => {
        setAccountSetupError("");
      }, 3000);
      return;
    }
    setLoading(false);
    setAccountCreated(true);
  };

  const getSalesforceToken = async () => {
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      return { success: true, access_token: response.data };
    } catch (err) {
      console.log("getSalesforceToken err on login");
      return { success: false, error: err };
    }
  };

  const checkForEmail = async (token) => {
    try {
      let checkEmailResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/auth/signup`,
        {
          params: {
            email: email,
            token: token,
          },
        }
      );

      return {
        success: true,
        numberOfRecords: checkEmailResponse.data?.totalSize,
        firstName: checkEmailResponse.data.records[0]?.Name,
        accountId: checkEmailResponse.data.records[0]?.AccountId,
        contactId: checkEmailResponse.data.records[0]?.Id,
        portalAccess:
          checkEmailResponse.data?.records[0]?.Resident_Manager_Portal_Role__c,
      };
    } catch (err) {
      console.log("checkForEmail err", err);
      return { success: false, error: err };
    }
  };

  if (accountCreated) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <p
          className="welcome"
          style={{
            marginTop: "30px",
            fontSize: "36px",
            letterSpacing: 0.4,
            textAlign: "center",
          }}
        >
          Account Created
        </p>
        <p
          className="welcome"
          style={{
            marginTop: "30px",
            fontSize: "36px",
            letterSpacing: 0.4,
            textAlign: "center",
          }}
        >
          Check your inbox for an email verification.
        </p>
        <Link to="/login">Return To Login Page</Link>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "50px",
      }}
    >
      <p
        className="welcome"
        style={{
          marginTop: "30px",
          fontSize: "36px",
          letterSpacing: 0.4,
          textAlign: "center",
        }}
      >
        WELCOME
      </p>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          attemptCreateAccount();
          setAccountSetupError("");
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: 200,
          }}
        >
          <Form.Group className="form_item">
            <Form.Control
              type="email"
              placeholder="EMAIL"
              value={email}
              onChange={(text) => {
                setEmail(text.target.value);
                // props.onASEmailChange(text.target.value);
              }}
              style={{
                textAlign: "center",
                minWidth: "300px",
                padding: "10px",
                height: "60px",
                border: "2px #EFEFEF solid",
                fontFamily: proximanova_light,
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="form_item">
            <Form.Control
              type="password"
              placeholder="PASSWORD"
              value={password1}
              onChange={(text) => {
                setPassword1(text.target.value);
                // props.onASPassword1Change(text.target.value);
              }}
              style={{
                textAlign: "center",
                minWidth: "300px",
                // width: "40vw",
                padding: "10px",
                height: "60px",
                border: "2px #EFEFEF solid",
                fontFamily: proximanova_light,
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="form_item">
            <Form.Control
              type="password"
              placeholder="CONFIRM PASSWORD"
              value={password2}
              onChange={(text) => {
                setPassword2(text.target.value);
                // props.onASPassword2Change(text.target.value);
              }}
              style={{
                textAlign: "center",
                minWidth: "300px",
                // width: "40vw",
                padding: "10px",
                height: "60px",
                border: "2px #EFEFEF solid",
                fontFamily: proximanova_light,
              }}
            ></Form.Control>
          </Form.Group>

          {accountSetupError !== "" && (
            <div
              style={{
                color: "#f34",
                marginTop: 10,
                paddingLeft: 10,
                marginBottom: 10,
              }}
            >
              {accountSetupError}
            </div>
          )}

          {loading && <div> Creating Account...</div>}
          <button
            className="login_button"
            style={{
              marginTop: "10px",
              color: "white",
              fontSize: "24px",
              backgroundColor: "black",
              padding: "10px",
              width: "190px",
              letterSpacing: "2.5px",
            }}
            type="Submit"
          >
            SUBMIT
          </button>
        </div>
      </Form>

      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "50vw",
          marginBottom: "30px",
        }}
      >
        <Link
          to={"/login"}
          style={{
            color: "black",
            textDecoration: "none",
            fontFamily: proximanova_light,
          }}
        >
          LOGIN{" "}
        </Link>
      </div>
    </div>
  );
}

export default connect(null, { addToken })(SignUp);
