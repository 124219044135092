/** @format */

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
// import search_icon from "../assets/buttons/search-icon.png";
import axios from "axios";
import { GLOBAL_DOMAIN } from "../actions/types";
import { useSelector } from "react-redux";
import "../css/history.css";
import "../css/clients.css";
import InfiniteScroll from "react-infinite-scroll-component";
import down_arrow from "../assets/buttons/down-arrow.png";
import up_arrow from "../assets/buttons/up-arrow.png";
import EditContactModal from "./EditContactModal";
import filter_icon from "../assets/buttons/filter.png";
import FilterModal from "./FilterModal";
import LabelValueSnippet from "./utils/LabelValueSnippet";
// import ContactCard from "./ContactCard";

function Clients() {
  const [clients, setClients] = useState([]);
  const [entities, setEntities] = useState([]);
  const [searchedClients, setSearchedClients] = useState([]);
  const [expandedContact, setExpandedContact] = useState({});
  const [editingContact, setEditingContact] = useState(false);
  const [creatingContact, setCreatingContact] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [noClients, setNoClients] = useState(false);
  const [userFilters, setUserFilters] = useState({});

  const { token } = useSelector((state) => state.auth);
  const { accountId } = useSelector((state) => state.account);

  useEffect(() => {
    let getClients = async () => {
      let entityId = accountId;
      if (!entityId) {
        entityId = localStorage.getItem("accountId");
      }
      let response = await axios.get(
        `${GLOBAL_DOMAIN}/api/clients/allClients`,
        {
          params: {
            token,
            accountId: entityId,
          },
        }
      );

      let entities = await axios.get(`${GLOBAL_DOMAIN}/api/clients/entities`, {
        params: {
          token,
          accountId: entityId,
        },
      });

      response.data.sort(function (a, b) {
        return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1;
      });
      setClients(response.data);
      setSearchedClients(response.data);

      setEntities(entities?.data);
    };
    getClients();
    // eslint-disable-next-line
  }, [token]);

  const getSearchedClients = async (filters = userFilters) => {
    let entityId = accountId;

    let response = await axios.get(
      `${GLOBAL_DOMAIN}/api/clients/searchedClients/${searchTerm}`,
      {
        params: {
          token,
          accountId: entityId,
          filters: filters,
        },
      }
    );
    if (!response?.data?.length) {
      setNoClients(true);
      setTimeout(() => {
        setNoClients(false);
      }, 4000);
    }
    response.data.sort(function (a, b) {
      return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1;
    });
    setSearchedClients(response.data);
  };

  const renderDate = (date) => {
    if (!date) {
      return null;
    }
    date = date.split("-");
    return `${date[1]}/${date[2]}/${date[0]}`;
  };

  const renderContactCard = (contact) => {
    return (
      <div
        key={contact.Id}
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "lightgray",
          marginBottom: "30px",
          padding: "10px",
          width: "80vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            if (expandedContact.Id === contact.Id) {
              setExpandedContact({});
            } else {
              setExpandedContact(contact);
            }
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              padding: "0px 0px 10px 0px",
              // alignItems: "center",
            }}
          >
            <p style={{}}>
              {contact.Name} - {contact?.Unit__c}
            </p>
            <p style={{ margin: 0 }}>{contact.Account?.Name}</p>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setEditingContact(true);
            }}
            style={{
              display: expandedContact.Id === contact.Id ? "flex" : "none",
              width: "100px",
              border: "1px solid",
              padding: "10px 14px",
              borderRadius: "5px",
              backgroundColor: "white",
              justifyContent: "center",
            }}
          >
            Edit
          </div>
          <img
            alt="details"
            style={{
              padding: "20px 20px 20px 10px",
            }}
            src={expandedContact.Id === contact.Id ? up_arrow : down_arrow}
          />
        </div>
        <div
          style={{
            display: expandedContact.Id === contact.Id ? "flex" : "none",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: expandedContact.Id === contact.Id ? "flex" : "none",
              flexDirection: "column",
              flex: 1,
              marginTop: "15px",
            }}
          >
            <LabelValueSnippet label="Email" value={contact?.Email} />
            <LabelValueSnippet label="Phone" value={contact?.Phone} />
            <LabelValueSnippet
              label="Status"
              value={
                contact?.Contact_Status__c === "Active" ? "Active" : "Inactive"
              }
            />
          </div>
          <div
            style={{
              display: expandedContact.Id === contact.Id ? "flex" : "none",
              flexDirection: "column",
              flex: 1,
              marginTop: "15px",
            }}
          >
            <LabelValueSnippet
              label="Lease Start Date"
              value={renderDate(contact?.LDS__c)}
            />
            <LabelValueSnippet
              label="Lease End Date"
              value={renderDate(contact?.LED__c)}
            />
            {contact?.Reason_for_New_Contact__c && (
              <LabelValueSnippet
                label="Reason for New Contact"
                value={contact?.Reason_for_New_Contact__c}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const newSearchValue = () => {
    let id = undefined;

    return (value) => {
      clearTimeout(id);
      id = setTimeout(() => {
        let filteredClients = [...clients];

        if (Object.keys(userFilters).length) {
          for (let field in userFilters) {
            filteredClients = filteredClients.filter((item) => {
              return item[field] === userFilters[field];
            });
          }
        }
        // setSearchedClients(filteredClients);

        let newClients = filteredClients.filter(
          (item) =>
            item.Name?.toLowerCase().includes(value.toLowerCase()) ||
            item.Unit__c?.toLowerCase().includes(value.toLowerCase()) ||
            item.Phone?.toLowerCase().includes(value.toLowerCase()) ||
            item.Account?.Name?.toLowerCase().includes(value.toLowerCase())
        );
        setSearchedClients(newClients);
        setSearchTerm(value);
      }, 500);
    };
  };
  let newSearch = newSearchValue();
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            margin: "2em 0em",
          }}
        >
          {clients.length > 0 && (
            <input
              autoComplete={"off"}
              placeholder={
                "Search Contacts by Name, Unit#, Email, Building or Phone"
              }
              style={{
                // marginBottom: "10px",
                margin: "0px 0px 0px 30px",
                paddingLeft: "10px",
                border: "2px black solid",
                width: "60%",
                height: "35px",
                fontSize: "16px",
                flex: 1,
              }}
              onFocus={() => {
                setExpandedContact({});
              }}
              onChange={(e) => {
                newSearch(e.target.value);
              }}
            />
          )}
          {clients.length > 0 && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowFilter(true);
              }}
              style={{
                backgroundColor: "transparent",
                border:
                  Object.keys(userFilters).length > 0
                    ? "2px solid green"
                    : "none",
                borderRadius: "10px",
                margin: "0px 20px 0px 40px",
              }}
            >
              <img
                alt="filter"
                src={filter_icon}
                style={{ maxHeight: "40px", paddingBottom: "0px" }}
              />
            </button>
          )}

          {clients.length > 0 && (
            <button
              className="AddNewButton"
              style={{
                fontSize: 16,
                padding: "10px",
                backgroundColor: "transparent",
                border: "black 1px solid",
                borderRadius: "5px",
                margin: "0px 0px 0px 20px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setCreatingContact(true);
              }}
            >
              Add New
            </button>
          )}
        </div>
        {!clients.length ? (
          <div>Loading Clients</div>
        ) : (
          <div>
            {Object.keys(userFilters).length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0px 0px 30px 0px",
                  flexWrap: "wrap",
                }}
              >
                <span style={{ marginRight: "5px" }}>Filters: </span>
                {Object.keys(userFilters).map((item) => {
                  if (item === "Contact_Status__c") {
                    return (
                      <span
                        key={item}
                        style={{
                          color: "white",
                          backgroundColor: "gray",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      >
                        Status: {userFilters[item]}
                      </span>
                    );
                  }
                  if (item === "AccountId") {
                    let account = entities.records.filter((item) => {
                      return item.Id === userFilters["AccountId"];
                    });
                    return (
                      <span
                        key={item}
                        style={{
                          color: "white",
                          backgroundColor: "gray",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      >
                        Account: {account?.[0].Name}
                      </span>
                    );
                  }
                  if (item === "Reason_for_New_Contact__c") {
                    return (
                      <span
                        key={item}
                        style={{
                          color: "white",
                          backgroundColor: "gray",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      >
                        Reason: {userFilters[item]}
                      </span>
                    );
                  }
                  return <p>{item}</p>;
                })}
                <span
                  key={"clear"}
                  onClick={async () => {
                    await getSearchedClients({});
                    setUserFilters({});
                  }}
                  style={{
                    color: "red",
                    backgroundColor: "white",
                    border: "1px solid black",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  Clear Filters
                </span>
              </div>
            )}

            <InfiniteScroll
              dataLength={searchedClients.length}
              //   next={this.fetchMoreData}
              // hasMore={true}
              //   loader={<h4>Loading...</h4>}
            >
              {searchedClients.length ? (
                <>
                  {searchedClients.map((item) => {
                    // return (
                    //   <ContactCard
                    //     key={item.Id}
                    //     contact={item}
                    //     editContact={() => {
                    //       setEditingContact(true);
                    //     }}
                    //     expandContact={(item)=> {
                    //       setExpandedContact(item)
                    //     }}
                    //   />
                    // );
                    return renderContactCard(item);
                  })}
                  {searchedClients.length < 10 && (
                    <div>
                      <button
                        style={{
                          alignSelf: "center",
                          fontSize: 16,
                          padding: "10px",
                          backgroundColor: "white",
                          border: "black 1px solid",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          getSearchedClients(userFilters);
                        }}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p>No Contacts</p>

                  {/* {Object.keys(userFilters).length === 0 ? ( */}
                  <button
                    style={{
                      alignSelf: "center",
                      fontSize: 16,
                      padding: "10px",
                      backgroundColor: "white",
                      border: "black 1px solid",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      getSearchedClients(userFilters);
                    }}
                  >
                    Load More
                  </button>
                  {/* ) : (
                    <button
                      style={{
                        alignSelf: "center",
                        fontSize: 16,
                        padding: "10px",
                        backgroundColor: "white",
                        border: "black 1px solid",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setSearchedClients(clients);
                        setUserFilters({});
                      }}
                    >
                      Clear Filters
                    </button>
                  )} */}
                  {noClients && <p>No Additional Contacts Found</p>}
                </div>
              )}
            </InfiniteScroll>
          </div>
        )}
        {showFilter && (
          <FilterModal
            show={showFilter}
            onHide={() => {
              setShowFilter(false);
            }}
            entities={entities?.records}
            editFilters={() => {}}
            applyFilter={async (filters) => {
              setUserFilters(filters);
              await getSearchedClients(filters);
              // if (Object.keys(filters).length) {
              //   let filteredClients = [...clients];
              //   filteredClients = filteredClients.filter(
              //     (item) =>
              //       item.Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              //       item.Unit__c?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              //       item.Phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              //       item.Account?.Name?.toLowerCase().includes(searchTerm.toLowerCase())
              //   );
              //   for (let field in filters) {
              //     filteredClients = filteredClients.filter((item) => {
              //       return item[field] === filters[field];
              //     });
              //   }
              //   setSearchedClients(filteredClients);
              // } else {
              //   setSearchedClients(clients);
              // }
              setShowFilter(false);
            }}
            filter={userFilters}
          />
        )}

        {(expandedContact.Id || creatingContact) && (
          <EditContactModal
            show={creatingContact || editingContact}
            entities={entities?.records}
            onHide={() => {
              setEditingContact(false);
              setCreatingContact(false);
            }}
            onUpdate={async (updatedContact) => {
              try {
                await axios.get(
                  `${GLOBAL_DOMAIN}/api/clients/updateClient/${updatedContact.Id}`,
                  {
                    params: {
                      token,
                      updatedContact,
                    },
                  }
                );
                await getSearchedClients();
              } catch (error) {
                console.log("ERR", error);
              }

              // setTimeout(() => {
              setEditingContact(false);
              // }, 4000);
              return true;
            }}
            onCreateNew={async (newContact) => {
              try {
                await axios.get(`${GLOBAL_DOMAIN}/api/clients/createContact`, {
                  params: {
                    token,
                    newContact,
                  },
                });
                await getSearchedClients();

                // let response = await axios.get(
                //   `${GLOBAL_DOMAIN}/api/clients/allClients`,
                //   {
                //     params: {
                //       token,
                //       accountId: accountId,
                //     },
                //   }
                // );

                // response.data.sort(function (a, b) {
                //   return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1;
                // });
                // setClients(response.data);
                // setSearchedClients(response.data);
              } catch (error) {}
              setCreatingContact(false);
              return true;
            }}
            creatingContact={creatingContact}
            contact={creatingContact ? {} : expandedContact}
          />
        )}
      </div>
    </>
  );
}

export default withRouter(Clients);
