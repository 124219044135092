import {
  LOGIN_ATTEMPTED,
  LI_EMAIL_CHANGED,
  LI_PASSWORD_CHANGED,
  LI_TOKEN_CHANGED,
  LI_CLEARALL,
  LI_ERROR
} from "../actions/types";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: "",
  token: "",
  loading: false
};

// const LOGIN_ATTEMPTED = "LOGIN_ATTEMPTED";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAIL = "LOGIN_FAIL";
// const LI_EMAIL_CHANGED = "LI_EMAIL_CHANGED";
// const LI_PASSWORD_CHANGED = "LI_PASSWORD_CHANGED";
// const LI_TOKEN_CHANGED = "LI_TOKEN_CHANGED";
// const LI_CLEARALL = "LI_CLEARALL";
// const LI_ERROR = "LI_ERROR";

// export const attemptLogin = () => {
//   return {
//     type: LOGIN_ATTEMPTED
//   };
// };

// export const onLIEmailChange = text => {
//   const lowerCaseText = text.toLowerCase();

//   return {
//     type: LI_EMAIL_CHANGED,
//     payload: lowerCaseText
//   };
// };

// export const onLIPasswordChange = text => {
//   return {
//     type: LI_PASSWORD_CHANGED,
//     payload: text
//   };
// };

// export const addToken = text => {
//   return {
//     type: LI_TOKEN_CHANGED,
//     payload: text
//   };
// };

// export const onLIClearFields = dispatch => {
//   return {
//     type: LI_CLEARALL,
//     payload: "clear all"
//   };
// };

// export const onLIError = err => ({
//   type: LI_ERROR,
//   payload: err
// });

let reducer = function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_ATTEMPTED:
      return { ...state, loading: true, error: "" };
    case LOGIN_SUCCESS:
      return INITIAL_STATE;
    case LOGIN_FAIL:
      return INITIAL_STATE;
    case LI_EMAIL_CHANGED:
      return { ...state, email: action.payload, loading: false, error: "" };
    case LI_PASSWORD_CHANGED:
      return { ...state, password: action.payload, loading: false, error: "" };
    case LI_TOKEN_CHANGED:
      return { ...state, token: action.payload, loading: false, error: "" };
    case LI_CLEARALL:
      return INITIAL_STATE;
    case LI_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}
export default reducer